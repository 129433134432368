import React from 'react'

const CardBlack = ({ text, styling, number }) => {
    return (
        <p className={styling}>{number} - {text}</p>
    )
}

CardBlack.defaultProps = {
    styling: "text-xl font-semibold",
}

export default CardBlack