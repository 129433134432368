import './App.css';
// import Header from './components/Header';
import Mnemonica from './components/Mnemonica/Mnemonica';
import Stack from './components/Stack/Stack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Nav from './components/Nav/Nav';

function App() {
  return (
    <div className="App">
      <title>Test</title>
      {/* <Header title="Storage" /> */}
      <Nav />
      
      <BrowserRouter>
        <Routes>
          <Route path="/mnemonica" element={<Mnemonica />} />
          <Route path="/stack" element={<Stack />} />
        </Routes>
      </BrowserRouter>

      
    </div>
  );
}

export default App;


// https://mui.com/components/dividers/#main-content
// https://tailwindcss.com/docs/padding