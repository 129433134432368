import React from 'react'
import CardBlack from '../Mnemonica/CardBlack'
import CardRed from '../Mnemonica/CardRed'

const Stack = () => {
    return (
        <div>
            <h2 className="text-2xl font-semibold p-3">Grouped Stack</h2>
            <div className="columns-4">
                <div className="columns-1">
                    <h2 className="text-xl font-semibold">Clubs</h2>
                    <CardBlack text="AC" number="43" />
                    <CardBlack text="2C" number="27" />
                    <CardBlack text="3C" number="4" />
                    <CardBlack text="4C" number="1" />
                    <CardBlack text="5C" number="30" />
                    <CardBlack text="6C" number="50" />
                    <CardBlack text="7C" number="47" />
                    <CardBlack text="8C" number="33" />
                    <CardBlack text="9C" number="44" />
                    <CardBlack text="10C" number="24" />
                    <CardBlack text="JC" number="36" />
                    <CardBlack text="KC" number="13" />
                    <CardBlack text="QC" number="18" />
                </div>
                <div className="columns-1">
                    <h2 className="text-xl font-semibold p-3 text-rose-600">Hearts</h2>
                    <CardRed text="AH" number="51" />
                    <CardRed text="2H" number="2" />
                    <CardRed text="3H" number="28" />
                    <CardRed text="4H" number="5" />
                    <CardRed text="5H" number="8" />
                    <CardRed text="6H" number="23" />
                    <CardRed text="7H" number="41" />
                    <CardRed text="8H" number="14" />
                    <CardRed text="9H" number="17" />
                    <CardRed text="10H" number="38" />
                    <CardRed text="JH" number="20" />
                    <CardRed text="QH" number="11" />
                    <CardRed text="KH" number="35" />
                </div>
                <div className="columns-1">
                    <h2 className="text-xl font-semibold p-3">Spades</h2>
                    <CardBlack text="AS" number="7" />
                    <CardBlack text="2S" number="10" />
                    <CardBlack text="3S" number="21" />
                    <CardBlack text="4S" number="40" />
                    <CardBlack text="5S" number="16" />
                    <CardBlack text="6S" number="15" />
                    <CardBlack text="7S" number="37" />
                    <CardBlack text="8S" number="22" />
                    <CardBlack text="9S" number="9" />
                    <CardBlack text="10S" number="34" />
                    <CardBlack text="JS" number="45" />
                    <CardBlack text="QS" number="48" />
                    <CardBlack text="KS" number="31" />
                </div>
                <div className="columns-1">
                    <h2 className="text-xl font-semibold p-3 text-rose-600">Diamonds</h2>
                    <CardRed text="AD" number="39" />
                    <CardRed text="2D" number="19" />
                    <CardRed text="3D" number="12" />
                    <CardRed text="4D" number="42" />
                    <CardRed text="5D" number="25" />
                    <CardRed text="6D" number="6" />
                    <CardRed text="7D" number="3" />
                    <CardRed text="8D" number="29" />
                    <CardRed text="9D" number="52" />
                    <CardRed text="10D" number="49" />
                    <CardRed text="JD" number="32" />
                    <CardRed text="QD" number="46" />
                    <CardRed text="KD" number="26" />
                </div>
            </div>
        </div>
    )
}

export default Stack
