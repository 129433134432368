const Nav = () => {
    return (
        <div className="p-3">
            <ul>
                <li className="inline p-3 text-xl font-semibold"><a href="/">Home</a></li>
                <li className="inline p-3 text-xl font-semibold"><a href="/Mnemonica">Mnemonica</a></li>
                <li className="inline p-3 text-xl font-semibold"><a href="/Stack">Stack</a></li>
            </ul>
        </div>
    )
}

export default Nav
