import React from 'react'

const CardRed = ({ text, styling, number }) => {
    return (
        <p className={styling}>{number} - {text}</p>
    )
}

CardRed.defaultProps = {
    styling: "text-rose-600 text-xl font-semibold",
}

export default CardRed